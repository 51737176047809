<template>
  <d-body-top-bar
    :title="title"
  >
    <div slot="tree">
      <label class="bg-doinsport-primary ml-1 mt-1">
        {{ $t('data.menu.dashboard') }}
      </label>
    </div>
  </d-body-top-bar>
</template>
<script>
export default {
  props: {
    routeName: {
      type: String,
      default: null
    }
  },
  computed: {
    title() {
      switch (this.routeName) {
        case 'email':
          return this.$t('views.marketing.content.middle.email-campaign');
        case 'sms':
          return this.$t('views.marketing.content.middle.sms-campaign');
        case 'push':
          return this.$t('views.marketing.content.middle.push-campaign');
        default:
          console.error('unknown campaign target');
      }
    }
  }
}
</script>
